
body {

    padding: 0;
    
    margin: 0;

    background-color: #f0f0f0;

    overscroll-behavior: none;    
    
    }

